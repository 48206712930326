import React from "react"
import PropTypes from "prop-types"

import { Link, StaticQuery, graphql } from "gatsby"

import Slider from "./home-slider"
import Footer from "../footer"
import Seo from "../seo"
import AboutTheFeatured from "./home-about-the-featured"
import Features from "../listings/media-top-list"
import News from "../listings/media-left-list"
import SiteMap from "./sitemap"
import SubmitStory from "../submit-story"

const Layout = ({ children }) => {

  let q = graphql`
        query  {
          recentFeatures: allStrapiArticles(limit: 6, skip: 0, sort: {fields: createdAt,order: ASC}, filter: {status: {eq:"published"},
          # isFeatured:{eq:false}, 
          slug: {ne: ""}, category: {slug: {eq: "custom-motorcycle"}}}) {
            edges {
              node {
                slug
                strapiId
                id
                excerpt
                createdAt
                title
                isFeatured
                category {
                  name
                }
                banner {
                  childImageSharp {
                    fixed(width: 400, height: 300, toFormat: JPG) {
                      srcSet
                      width
                      height
                      src
                      aspectRatio
                    }
                  }
                }
              }
            }
          }
          recentNews:allStrapiArticles(limit: 6, skip: 0, sort: {fields: createdAt, order: ASC}, filter: {status: {eq:"published"}, slug: {ne: ""}, 
            category: {slug: {eq: "howtos-and-guides"}}
            }) {
            edges {
              node {
                slug
                strapiId
                id
                excerpt
                createdAt
                title
                isFeatured
                category {
                  name
                }
                banner {
                  childImageSharp {
                    fixed(width: 400, height: 300, toFormat: JPG) {
                      srcSet
                      width
                      height
                      src
                      aspectRatio
                    }
                  }
                }
              }
            }
          }
        }
      `
  return (
    <>
      <Seo />
      <Slider />
      <AboutTheFeatured />
      <div className="uk-section-muted uk-section uk-section-large" uk-scrollspy="target: [uk-scrollspy-class]; cls: uk-animation-fade; delay: 200;">
        <div className="uk-container">

          <StaticQuery
            query={q}
            render={({ recentFeatures, recentNews }) => {
              return (
                <div className="uk-grid-margin uk-grid" uk-grid="">
                  <div className="uk-flex-auto uk-width-2-3@s uk-first-column">
                    < Features
                      data={recentFeatures}
                      title={'RECENT FEATURES'}
                      showAllURL={'custom-motorcycle'} // custom-motorcycle
                    />
                  </div>
                  <div className="uk-flex-auto uk-width-1-3@s uk-first-column">
                    <News
                      data={recentNews}
                      title="HOW TOs & GUIDES"
                    />
                  </div>
                </div>
              )





            }
            }
          />


        </div>
      </div>
      {/* <SiteMap /> */}
      <SubmitStory />
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout  