import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import ReactMarkdown from "react-markdown"



const Slider = ({ data }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
 article:strapiArticles(status:  {eq: "published"}, isFeatured: {eq: true}) {
            strapiId
            slug
            id
            excerpt
            createdAt
            title
            category {
              name
            }
            featureAboutImage {
              childImageSharp {
                fixed(width: 800,toFormat: JPG) {
                  srcSet
                  width
                  height
                  src
                }
              }
            }
          }
        }

      `}
      render={(result) => {

        // console.log(data)
        let data = result.article
        return (
          <div id="home-section1" className="uk-section-default uk-section uk-section-large" uk-scrollspy="target: [uk-scrollspy-class]; cls: uk-animation-slide-left-medium; delay: false;">

            <div className="uk-container">
              <div className="uk-grid-margin uk-grid" uk-grid="">
                <div className="uk-grid-item-match uk-flex-middle uk-flex-auto uk-width-1-3@m uk-first-column">
                  <div className="uk-panel uk-width-1-1">
                    <h6>BIKE OF THE WEEK</h6>
                    {/* <h2 className="uk-h1 uk-margin-remove-top uk-scrollspy-inview uk-animation-slide-left-medium" uk-scrollspy-className="">
                      {data.title}
                    </h2> */}
                    {/* <h4>SPECIFICATIONS</h4> */}
                    <div className="uk-margin-medium uk-margin-remove-top uk-scrollspy-inview uk-animation-slide-left-medium" uk-scrollspy-className="">
                      <ReactMarkdown source={data.excerpt} />
                    </div>
                    <div className="uk-margin-medium uk-scrollspy-inview uk-animation-slide-left-medium" uk-scrollspy-className="">
                      <Link className="el-content uk-button uk-button-text" to={`article/${data.slug}`}>Read Full Story</Link>
                    </div>

                  </div>
                </div>
                <div className="uk-grid-item-match uk-flex-middle uk-flex-auto uk-width-2-3@m">
                  <div className="uk-panel uk-width-1-1">
                    <div className="uk-margin uk-scrollspy-inview uk-animation-slide-left-medium" uk-scrollspy-className="">
                      <Link className="el-link" to={`article/${data.slug}`}>
                        <img className="el-image" src={data.featureAboutImage.childImageSharp.fixed.src} />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
      }
    />
  )
}

export default Slider
