import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Layout from "../components/home/layoutHome"
import ArticlesComponent from "../components/articles"

import "../assets/css/main.css"
import "../assets/css/social.css"
import SEO from "../components/seo"


const IndexPage = () => (
  <Layout>
    <StaticQuery
      query={graphql`
        query {
          allStrapiArticles {
            edges {
              node {
                strapiId
                title
                content
                category {
                  name
                }
                banner{
                 publicURL
                }
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <div className="uk-section">
            <div className="uk-container uk-container-large">
              <ArticlesComponent articles={data.allStrapiArticles.edges} />
            </div>
          </div>
        )
      }}
    />
  </Layout>
)

export default IndexPage 