import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"

const RecentFeatures = ({ data, title, showAllURL }) => {
  if (!data || !data.edges || !data.edges[0]) return null

  let list = data.edges.map(({ node }) => {
    return (

      <a className="uk-margin-bottom el-item uk-card uk-card-default uk-card-hover uk-display-block uk-link-toggle uk-scrollspy-inview uk-animation-fade"
        href={`/article/${node.slug}`} uk-scrollspy-className="" >
        <div class="uk-flex-inline uk-width-1-1@s" uk-grid>
          <div class="uk-width-1-3 uk-card-media-left uk-cover-container uk-flex-right">
            <img width="120" height="120" src={node.banner.childImageSharp.fixed.src} alt="" uk-cover />
            {/* <canvas width="600" height="400"></canvas> */}
          </div>
          <div className="uk-width-2-3">
            <div class="uk-card-body uk-padding-small">
              {/* <h3 class="uk-card-title">{node.title}</h3> */}
              <p>{node.title}</p>
            </div>
          </div>
        </div>
      </a >
    )
  }
  )


  return (

    <div className="uk-grid-margin uk-grid" uk-grid="">
      {title ?
        <div className="uk-flex-auto uk-width-2-3@s uk-first-column">
          <h2 className="uk-h4 uk-scrollspy-inview uk-animation-fade" uk-scrollspy-className="" >{title}</h2>
        </div>
        : null}
      {showAllURL ?
        <div className="uk-flex-auto uk-width-1-3@s">
          <div className="uk-margin uk-text-right@s uk-scrollspy-inview uk-animation-fade" uk-scrollspy-className="" >
            <a className="el-content uk-button uk-button-text" href={`/all/${showAllURL}`}>Show all</a>
          </div>
        </div>
        : null}
      <div className="uk-flex-auto uk-width-1-1@m uk-first-column">
        <div className="uk-margin">
          {/* <div className="uk-child-width-1-1 uk-child-width-1-2@m uk-grid-medium uk-grid-match uk-grid" uk-grid=""> */}
          <div className="">
            {list}
          </div>
        </div>
      </div>
    </div>

  )


}

export default RecentFeatures
