import React from "react"



const MediaTopList = ({ data, title, columns, showAllURL }) => {
  if (!data || !data.edges || !data.edges[0]) return null
  let list = data.edges.map(({ node }) => {
    return (
      <div key={node.id} className="uk-margin-bottom">
        <a className="el-item uk-card uk-card-default uk-card-hover uk-display-block uk-link-toggle uk-scrollspy-inview uk-animation-fade"
          href={`/article/${node.slug}`} uk-scrollspy-className="" >
          <div className="uk-card-media-top">
            <img
              src={node.banner.childImageSharp.fixed.src}
            />
          </div>
          <div className="uk-card-body uk-margin-remove-first-child">
            <p className="el-title uk-h6 uk-margin-top uk-margin-remove-bottom">{node.title}</p>
          </div>
        </a>
      </div>
    )
  }
  )

  return (

    <div className="uk-grid-margin uk-grid">
      {title ?
        <div className="uk-flex-auto uk-width-2-3@s uk-first-column">
          <h2 className="uk-h4 uk-scrollspy-inview uk-animation-fade" uk-scrollspy-className="" >{title}</h2>
        </div>
        : null}
      {showAllURL ?
        <div className="uk-flex-auto uk-width-1-3@s">
          <div className="uk-margin uk-text-right@s uk-scrollspy-inview uk-animation-fade" uk-scrollspy-className="" >
            <a className="el-content uk-button uk-button-text" href={`/all/${showAllURL}`}>ALL</a>
          </div>
        </div>
        : null}
        <br/>
        <br/>
      <div className="uk-flex-auto uk-width-1-1@m uk-first-column">
        <div className="uk-margin uk-text-left">
          <div className={`uk-child-width-1-${columns || 3}@m uk-grid-medium uk-grid-match uk-grid`}>
            {list}
          </div>
        </div>
      </div>
    </div>

  )
}

export default MediaTopList
