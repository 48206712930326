import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"

import Nav from '../nav'

const Slider = () => (
  <div className="head-banner-wrap">
    <Nav />
    <div className="uk-section-default uk-light" uk-scrollspy="target: [uk-scrollspy-class]; cls: uk-animation-fade; delay: false;" tm-header-transparent="light" tm-header-transparent-placeholder="">
      <StaticQuery
        query={graphql`
          query  {
          strapiArticles(isFeatured: {eq: true}) {
            banner {
              childImageSharp {
                original{
                  height
                  width
                  src
                }
              }
            }
            title
            featureTitle
            featureDescription
            slug
            content
            excerpt
          }
        }
      `}
        render={({ strapiArticles }) => {
          return <div data-src={strapiArticles.banner.childImageSharp.original.src}
            // data-srcset="/themes/wordpress/2016/max/wp-admin/admin-ajax.php?action=kernel&p=theme%2Fimage&src=WyJ3cC1jb250ZW50XC91cGxvYWRzXC9ob21lLWhlYWRlci5qcGciLFtbImRvUmVzaXplIixbNzY4LDQzMiw3NjgsNDMyXV0sWyJkb0Nyb3AiLFs3NjgsNDMyLDAsMF1dLFsidHlwZSIsWyJ3ZWJwIiwiODUiXV1dXQ%3D%3D&hash=f29f309f69ef6a3ba6f11ad86ef131ab 768w, /themes/wordpress/2016/max/wp-content/themes/yootheme/cache/home-header-96d89a35.webp 1024w, /themes/wordpress/2016/max/wp-content/themes/yootheme/cache/home-header-d3fe7fe8.webp 1366w, /themes/wordpress/2016/max/wp-content/themes/yootheme/cache/home-header-5b0b38d3.webp 1600w, /themes/wordpress/2016/max/wp-content/themes/yootheme/cache/home-header-b289e3cc.webp 1920w, /themes/wordpress/2016/max/wp-content/themes/yootheme/cache/home-header-6ba2a601.webp 2560w"
            // data-sizes="(max-aspect-ratio: 2560/1440) 178vh"
            uk-img="" className="uk-background-norepeat uk-background-cover uk-section uk-section-xlarge uk-background-top-center"
            style={{ backgroundImage: `url("${strapiArticles.banner.childImageSharp.original.src}")` }}>
            <div className="uk-container">
              <div className="tm-header-placeholder uk-margin-remove-adjacent" style={{ height: '80' }}></div>
              <div className="uk-grid-margin uk-grid uk-grid-stack" uk-grid="s">
                <div className="uk-flex-auto uk-width-1-1@m uk-first-column">

                  <h1 className="" uk-scrollspy-className="">
                    {strapiArticles.featureTitle}
                  </h1>
                  <h2>{strapiArticles.featureDescription}</h2>
                  <br className="uk-visible@s" />
                  <br className="uk-visible@s" />
                  <br className="uk-visible@s" />
                  <br className="uk-visible@s" />
                  <br className="uk-visible@s" />

                  <div className="uk-margin uk-scrollspy-inview uk-animation-fade" uk-scrollspy-className="">
                    {/* <a className="el-content">FACEBOOK: Moto Minuto</a>
                          <br className="uk-visible@s" />
                          <a className="el-content">INSTAGRAM: @motominutoph</a>
                          <br/> */}
                    {/* <Link className="uk-button uk-button-default" to={`/article/${strapiArticles.slug}`}>READ FULL STORY</Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        }}
      />



    </div>
  </div>
)

export default Slider
